<template>
  <div id="node">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <i class="fas fa-code-branch"></i>
          {{node.name}} <small>({{node.mac}})</small>
          <button type="button" class="button is-small is-success" style="float:right;" @click="new_device=true">
            <span class="mr-2 is-hidden-mobile">เพิ่มมิเตอร์ใหม่</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <small class="is-size-7 is-hidden-mobile" style="float:right;">(จำนวนมิเตอร์: {{Devices.length}} เครื่อง)</small>
          <ul>
            <li><a @click="redirect('/member/mornitor')">สาขา</a></li>
            <li><a @click="redirect('/member/branch/'+node.b_id)">{{branch.name}}</a></li>
            <li class="is-active"><a>{{node.name}} <small class="ml-1 is-size-7">({{node.mac}})</small></a></li>
          </ul>
        </nav>
        <div class="has-text-centered" v-if="!Devices.length">
          <hr>ไม่พบข้อมูลมิเตอร์ โปรดเพิ่มมิเตอร์ใหม่
        </div>
        <div class="columns is-multiline" v-else>
          <div class="column is-one-third-desktop" v-for="(val,key) in Devices" :key="key">
            <article class="message" :class="{'is-success':parseInt(val.enable),'is-danger':!parseInt(val.enable)}">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer" @click="redirect('/member/device/'+val.id)">
                  <i class="fas fa-tablet-alt"></i>
                  Device{{val.number}} <small v-if="val.detail">({{val.detail}})</small>
                </p>
                <div class="dropdown is-right is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button is-xs">
                      <i class="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div class="dropdown-menu">
                    <div class="dropdown-content">
                      <a class="dropdown-item" @click="device=val; new_device=true;">
                        แก้ไขข้อมูล
                      </a>
                      <!--
                      <hr class="dropdown-divider">
                      <a class="dropdown-item has-text-danger" @click="remove_device(val)">
                        ลบข้อมูล
                      </a>
                      -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-body has-text-left py-2 is-size-6 is-size-7-mobile cursor_pointer" v-if="refresh_d_id!==val.id" @click="redirect('/member/device/'+val.id)">
                <small v-if="!val.log_created">มิเตอร์นี้ยังไม่มีข้อมูลการใช้งาน</small>
                <div v-else>
                  <p >Current : {{val.Current_a}} A</p>
                  <p >Total Power: {{val.Power}} W</p>
                  <p >Power Factor: {{val.PF}}</p>
                  <p >Imp Energy: {{val.Imp_Energy}} Kwh</p>
                  <p >Exp Energy: {{val.Exp_Energy}} Kwh</p>
                  <p >Total Energy: {{val.Total_Energy}} Kwh</p>
                </div>
              </div>
              <!-- has-text-centered -->
              <div class="message-footer is-size-6 is-size-7-mobile px-5" v-if="refresh_d_id!==val.id">
                <small v-if="val.log_created">{{val.log_created | date_data}}</small>
              </div>
              <div class="message-body p-0" v-else>
                <button class="button p-6 w-100 has-background-success-light is-loading"></button>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <modal-device :show="new_device" :mac="node.mac" :number="next_number" :device="device" @submit="submit_device" @close="new_device=false; device={};"></modal-device>

  </div>
</template>

<script>
import ModalDevice from '@/components/Modal/Device'

export default {
  name: 'MemberNode',
  components:{ ModalDevice },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
  },
  data(){
    return {
      n_id: this.$route.params.id,
      branch: {},
      node: {},
      device: {},
      new_device: false,
      refresh_d_id: 0,
    }
  },
  created(){
    const INDEX = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.n_id));
    if(INDEX!==-1){
      this.node = this.nodes[INDEX];

      const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
      this.branch = this.branchs[INDEX_B];
    }else{
      this.redirect("/member/mornitor")
    }
  },
  mounted(){
    // console.log(this.loaded.get_device);
    if(!this.loaded.get_device){
      this.$emit('get_device',this.n_id);
    }else{
      const INDEX_ = this.loaded.get_device.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.n_id));
      if(INDEX_==-1){
        this.$emit('get_device',this.n_id);
      }
    }
    // this.updateLastedLog();

    if(this.node.mac){
      if(this.client){
        this.$emit("subscribeMQTT",this.node.mac);
      }else{
        this.$emit("connectMQTT",this.node.mac);
      }
      this.get_device_log_for_each_devices();
    }
  },
  methods:{
    get_device_log_for_each_devices(){
      if(!this.Devices.length){
        setTimeout(() => {
          this.get_device_log_for_each_devices();
        }, 750);
        return false;
      }

      this.Devices.forEach((e,i) => {
        if(!this.loaded.get_device_log){
          setTimeout(() => {
            this.$emit('get_device_log', e.id);
          }, (i+1)*750);
        }else{
          const INDEX_ = this.loaded.get_device_log.map((d_id) => d_id).indexOf(e.id);
          if(INDEX_==-1){
            setTimeout(() => {
              this.$emit('get_device_log', e.id);
            }, (i+1)*750);
          }
        }
      });
    },
    updateLastedLog(device_log){
      this.devices.forEach((e) => {
        let thisDeviceLog = device_log.filter((log) => {
          // console.log(log,log.d_id,e.id,log.d_id==e.id);
          return log.d_id==e.id
        });
        if(!thisDeviceLog.length)return false;

        // console.log(thisDeviceLog[0],e.id);
        const IMP_ENERGY = (thisDeviceLog[0].Imp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Imp_Energy) : parseInt(0);
        const EXP_ENERGY = (thisDeviceLog[0].Exp_Energy!=="-") ? parseFloat(thisDeviceLog[0].Exp_Energy) : parseInt(0);

        e.Power = (thisDeviceLog[0].Power_t!=="-")?thisDeviceLog[0].Power_t:thisDeviceLog[0].Power_a;
        e.PF = (thisDeviceLog[0].PF_t!=="-")?thisDeviceLog[0].PF_t:thisDeviceLog[0].PF_a;
        
        e.Current_a = thisDeviceLog[0].Current_a;
        e.Power_t = thisDeviceLog[0].Power_t;
        e.PF_t = thisDeviceLog[0].PF_t;
        e.Imp_Energy = thisDeviceLog[0].Imp_Energy;
        e.Exp_Energy = thisDeviceLog[0].Exp_Energy;
        e.Total_Energy = IMP_ENERGY + EXP_ENERGY;

        // e.log_created = thisDeviceLog[0].created;
        // console.log(thisDeviceLog[0].created,thisDeviceLog[0].date_now);
        var d = new Date(parseInt(thisDeviceLog[0].date_now)),
        dformat = [d.getMonth()+1,
                   d.getDate(),
                   d.getFullYear()].join('-')+' '+
                  [d.getHours(),
                   d.getMinutes(),
                   d.getSeconds()].join(':');
       // console.log(thisDeviceLog[0].date_now,dformat);
        e.log_created = dformat;

        this.refresh_d_id = e.id;
      });
    },
    submit_device(input){
      const INDEX = this.devices.map((d) => d.number).indexOf(input.number);
      if(!input.id){
        if(INDEX!==-1){
          this.$swal({
            title: "ไม่สามารถเพิ่มอุปกรณ์ได้!",
            text: "คุณมีอุปกรณ์หมายเลขนี้อยู่แล้ว \n (Device Number: "+input.number+")",
            className: "text-center",
            icon: "warning",
          });
        }else{
          this.submit_new_device(input);
          this.new_device = false;
        }
      }else{
        if(INDEX!==-1 && input.number!==this.device.number){
          this.$swal({
            title: "ไม่สามารถเพิ่มอุปกรณ์ได้!",
            text: "คุณมีอุปกรณ์หมายเลขนี้อยู่แล้ว \n (Device Number: "+input.number+")",
            className: "text-center",
            icon: "warning",
          });
        }else if(input.number!==this.device.number){
          this.$swal({
            title: "เปลี่ยนหมายเลขมิเตอร์?",
            text: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนหมายเลขมิเตอร์นี้ \n\n (หมายเลขเดิม: "+this.device.number+") \n (หมายเลขใหม่: "+input.number+")",
            className: "text-center",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              this.submit_edit_device(input);
              this.new_device = false;
            }
          });
        }else{
          this.submit_edit_device(input);
          this.new_device = false;
        }
      }
    },
    async submit_new_device(input){
      var url = this.api+'device/add/'+this.secret;
      var data = new FormData();
      data.set('n_id', this.n_id);
      data.set('number', input.number);
      data.set('detail', input.detail);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          this.devices.push(response.data.data);
          this.$swal("","เพิ่มมิเตอร์ใหม่","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    async submit_edit_device(input){
      var url = this.api+'device/save/'+this.secret;
      var data = new FormData();
      Object.keys(input).forEach((key) => {
        data.set(key, input[key]);
      });
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.device[key] = input[key];
          });
          this.device = {};
          this.$swal("","Successful Save","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
  },
  computed:{
    Devices(){
      return this.devices.filter(d => { return parseInt(d.n_id)==parseInt(this.n_id) });
    },
    next_number(){
      if(this.Devices.length){
        return Math.max.apply(null, this.Devices.map(d => d.number)) + 1;
      }else{
        return 1;
      }
    }
  },
  watch:{
    device_log(val){
      this.updateLastedLog(val)
    },
    refresh_d_id(val){
      if(val)setTimeout(() => this.refresh_d_id=0, 1);
    }
  }
}
</script>
