<template>
  <div class="modal" :class="{'is-active':show}">
    <div class="modal-background"></div>
    <div class="modal-card has-text-left">
      <header class="modal-card-head">
        <p class="modal-card-title is-size-4 is-size-5-mobile">{{(device.id)?"Device Number "+device.number:"Create Device"}}</p>
        <button class="delete" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submit">
          <button type="submit" ref="save_edit_device" hidden></button>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Mac</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <span class="input is-success">{{mac}}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Number</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="number" placeholder="Device Number" v-model="input.number" min="0" required>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Detail</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Device's Detail" v-model="input.detail">
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="!device.id">
            <div class="field-label is-normal">
              <label class="label">Type</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="select">
                  <select v-model="input.type">
                    <option value="1">AC Converter</option>
                    <option value="2">Inverter</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal" v-if="device.id">
            <div class="field-label is-normal">
              <label class="label">Enable</label>
            </div>
            <div class="field-body">
              <div class="field">
                <label class="switch">
                  <input type="checkbox" v-model="enable">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="w-100">
          <button type="button" class="button is-small is-success float-right mx-1" @click="$refs.save_edit_device.click()">Save</button>
          <button type="button" class="button is-small is-default float-right mx-1" @click="$emit('close')">Cancel</button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDevice',
  props: {
    show: Boolean,
    mac: String,
    device: Object,
    number: {
      type: Number,
      default: 0,
    },
  },
  data(){
    return {
      input: {
        number: "",
        type: 0,
        detail: "",
      },
      enable: false,
    }
  },
  methods:{
    set_input(){
      this.input.id = this.device.id;
      this.input.number = this.device.number;
      this.input.detail = this.device.detail;
      this.input.type = this.device.type;
    },
    set_enable(){
      if(parseInt(this.device.enable))this.enable = true;
    },
    submit(){
      this.input.enable=(this.enable)?1:0;
      this.$emit('submit',this.input);
    }
  },
  watch:{
    show(){
      this.input = {};
      if(this.device.id){
        this.set_input();
        this.set_enable();
      }else{
        this.input.number = this.number;
        this.input.type = 1;
        this.input.detail = "";
      }
    }
  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  /* background-color: #2196F3; */
  background-color: #48c78e;
}
input:focus + .slider {
  /* box-shadow: 0 0 1px #2196F3; */
  box-shadow: 0 0 1px #48c78e;
}
input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
